import { helpers } from "vuelidate/lib/validators";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";

// Settings for app wide defaults and pre-parsing .env variables
export const isDev = process.env.NODE_ENV === "development";
export const apiBaseUrl =
  (isDev
    ? process.env.VUE_APP_API_BASE_URL || "https://localhost:5001"
    : process.env.VUE_APP_API_BASE_URL) ?? "";

// Speech SDK settings
export const speechConfigSettings = {
  subscriptionKey: "0502e99b0a3b47b18668e080c1a5db79",
  serviceRegion: "germanywestcentral",
  enableAudioLogging: false,
  speechRecognitionLanguage: "de-DE",
  profanityOption: sdk.ProfanityOption.Raw, // raw = unfiltered
  requestWordLevelTimestamps: true,
  enableDictation: true,
  outputFormat: 1, // extendet output (1) needed for nBest and confidence
};

// Hardcoded defaults
// TODO: Define time limit settings for all recorders per category,
//  e.g. recordingSettings.user.maxLen, recordingSettings.feedback.maxLen, etc.
export const recordingSettings = {
  expectedLen: 15 * 60, // Recommended/expected recording time in seconds
  maxLen: 18 * 60, // Max allowed recording time in seconds (TED Talk)
};

// Changing these values afterwards may lead to existing users unable to login with the new rules
export const passwordSettings = {
  passwordRules: helpers.regex(
    "passwordRules",
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{12,32}$/,
  ),
  minLength: 12,
  maxLength: 32,
};

export const chatSettings = {
  showSupportFABInsteadChat:
    process.env.VUE_APP_USE_SUPPORT_FAB === "true" || false, // Show a support button & dialog instead of the chat // TODO: Add boolean parser
  chatReadyPollingInterval: 200, // Polling to check if the chat is displayed, in ms
  chatReadyRetries: 3, // Required to make sure the widgets inline style was really applied after init (to allow overriding the style)
};
