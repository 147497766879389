import { ActionTree } from "vuex";

import { SessionsState } from ".";
import api from "@/core/utils/api";
import { RootState } from "../root";
import i18n from "@/core/plugins/i18n";
import { RemoveFromShared, SessionsProgress, User } from "@/core/models";
import { MetaAnalysis, Session } from "@/core/models/sessions";

const actions: ActionTree<SessionsState, RootState> = {
  clearSessions({ state }): void {
    state.sessions = [];
    state.retrieved = false;
  },

  async getProgress({ state }) {
    state.progressLoading = true;
    try {
      const end = "/api/Sessions/Progress";
      state.progress = (await api.get(end)) as SessionsProgress;
      state.progressRetrieved = true;
    } catch (error) {
      console.log(error);
    }
    state.progressLoading = false;
  },

  async getSlimSessions({ state }) {
    state.loading = true;
    try {
      const end = "/api/Sessions/Slim";
      const res = (await api.get(end)) as any[];
      const existing = state.sessions.map(s => s.ID);
      const newSessions = res
        .map(x => Session.parseSlim(x))
        .filter(x => !existing.includes(x.ID));
      state.sessions = [...state.sessions, ...newSessions];
      state.retrieved = true;
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  async clearSlimSessions({ state }) {
    state.sessions = [];
    state.retrieved = false;
  },

  async getIncompleteSessions({ state }) {
    try {
      const ids = state.sessions.filter(x => !x.Completed).map(x => x.ID);
      if (!ids.length) return;

      const end = `/api/Sessions/Incomplete?ids=${ids.join("-")}`;
      const res = (await api.get(end)) as any[];
      const fetched = Session.parseSessions(res);
      const oldSessions = state.sessions
        .slice(0)
        .filter(x => !ids.includes(x.ID));
      state.sessions = [...oldSessions, ...fetched];
      console.log("[actions] Fetched incomplete sessions");
    } catch (error) {
      console.log(error);
    }
  },

  async uploadSession({ state }, data: FormData) {
    state.uploadingSession = true;

    try {
      const res = await api.post("/api/Sessions/CreateSession", data, {
        headers: { ContentType: "multipart/form-data" },
      });
      const s = Session.parseSingleSession(res);
      const newSessions = [s, ...state.sessions];
      state.sessions = [];
      state.sessions = newSessions;
    } catch (error) {
      console.log(error);
    }

    state.uploadingSession = false;
  },

  async getSessions({ commit, state }) {
    state.metaLoading = true;
    state.loading = true;
    state.retrieved = false;

    try {
      const res = await api.get("/api/Sessions/ForWeb");
      state.retrieved = true;
      commit("getSessionsSuccess", res);
    } catch (error) {
      console.log(error);
    }

    state.loading = false;
    state.metaLoading = false;
  },

  async loadSharedSessions({ commit, state }) {
    state.loading = true;
    state.retrieved = false;

    try {
      const res = await api.get("/api/sessions/shared");
      state.retrieved = true;
      commit("getSharedSessionsSuccess", res);
    } catch (error) {
      console.log(error);
    }

    state.loading = false;
  },

  async getSingleSession({ state }, id: number) {
    let idx = state.sessions.findIndex(s => s.ID === id);

    state.gettingSingleSession = true;
    state.retrievedSessionId = id;
    try {
      if (idx !== -1) {
        state.sessions[idx].Loading = true;
        console.log("Set state loading for ", state.sessions[idx].ID);
      }
      const res = await api.get(`/api/Sessions/${id}`);
      const session = Session.parseSingleSession(res);
      session.Loaded = true;
      session.Loading = false;
      const copy = state.sessions.slice(0);
      idx = state.sessions.findIndex(s => s.ID === id);
      if (idx !== -1) copy[idx] = JSON.parse(JSON.stringify(session));
      else copy.push(session);

      state.sessions = copy;
    } catch (error) {
      console.log(error);
      state.sessions.findIndex(s => s.ID === id);
      if (idx !== -1) state.sessions[idx].Loading = false;
    }

    state.gettingSingleSession = false;
    state.retrievedSessionId = 0;
  },

  async getSingleSharedSession({ state }, id: number) {
    let idx = state.sessions.findIndex(s => s.ID === id);

    state.gettingSingleSession = true;
    state.retrievedSessionId = id;
    try {
      if (idx !== -1) {
        state.sessions[idx].Loading = true;
        console.log("Set state loading for ", state.sessions[idx].ID);
      }
      const res = await api.get(`/api/Sessions/shared/${id}`);
      const session = Session.parseSingleSession(res);
      session.Loaded = true;
      session.Loading = false;
      const copy = state.sessions.slice(0);
      idx = state.sessions.findIndex(s => s.ID === id);

      if (idx !== -1) copy[idx] = JSON.parse(JSON.stringify(session));
      else copy.push(session);

      state.sessions = copy;
    } catch (error) {
      console.log(error);
      state.sessions.findIndex(s => s.ID === id);
      if (idx !== -1) state.sessions[idx].Loading = false;
    }

    state.gettingSingleSession = false;
    state.retrievedSessionId = 0;
  },

  async deleteSession({ commit, state }, id: number) {
    state.removingSession = true;
    state.removedSessionId = id;

    try {
      await api.delete(`/api/Sessions/${id}`);
      commit("deleteSessionSuccess", id);
      const msg = i18n.t("snack.sess.delsuccess").toString();
      commit("displaySnackbar", msg, { root: true });
    } catch (error) {
      console.log(error);
      const msg = i18n.t("snack.sess.delfail").toString();
      commit("displaySnackbar", msg, { root: true });
    }

    state.removingSession = false;
    state.removedSessionId = 0;
  },

  async updateSettings({ commit, state }, payload: any) {
    state.metaLoading = true;
    try {
      const endpoint = "/api/Sessions/SaveSettings";
      const { data } = (await api.post(endpoint, payload)) as { data: any[] };

      const { attributes, value } = payload;
      state.metaSettings = { Attributes: attributes, NumOfSessions: value };
      state.metaAnalysis = data.map(m => new MetaAnalysis(m));
    } catch (errors) {
      const msg = i18n.t("snack.sess.metafail").toString();
      commit("displaySnackbar", msg, { root: true });
    }
    state.metaLoading = false;
  },

  async getBenchmarks({ commit, state }) {
    state.benchmarksLoading = true;
    state.benchmarksRetrieved = false;
    try {
      const end = "/api/Sessions/GetBaselineSessions";
      const { user, sessions } = (await api.get(end)) as any;
      state.benchmarkUser = User.parse(user);
      state.benchmarks = Session.parseSessions(sessions);
      state.benchmarksRetrieved = true;
    } catch (errors) {
      const msg = i18n.t("snack.sess.benchfail").toString();
      commit("displaySnackbar", msg, { root: true });
    }
    state.benchmarksLoading = false;
  },

  async uploadBaseline({ commit, state }, payload) {
    state.baselineLoading = true;
    try {
      const endpoint = "api/Sessions/Initial";
      await api.post(endpoint, payload, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (error: any) {
      const message = i18n.t("snack.sess.basefail").toString();
      commit("displaySnackbar", error.description || message, { root: true });
    }
    state.baselineLoading = false;
  },

  setBenchmark({ state }, payload) {
    console.log(payload);
    state.benchmark = payload;
  },
  clearBenchmark({ state }) {
    state.benchmark = null;
  },

  addSession({ state }, data: any) {
    try {
      const session = Session.parseSingleSession(data);
      state.sessions = [...state.sessions, session];
    } catch (error) {
      console.log(error);
    }
  },

  update({ state }, data: any) {
    const { id, name, sharedWith } = data;
    const idx = state.sessions.findIndex(x => x.ID === id);
    if (idx === -1) return;
    const copy = state.sessions.slice(0);
    copy[idx].Name = name;
    copy[idx].SharedWith = sharedWith;
    state.sessions = copy;
  },

  updateShared({ state }, data: any) {
    console.log("updateShared", data);
    const { sessionId, hasFeedback } = data;
    const idx = state.sharedSessions.findIndex(x => x.sessionId === sessionId);
    console.log("updateShared", idx);
    if (idx === -1) return;
    const copy = state.sharedSessions.slice(0);
    copy[idx].hasFeedback = hasFeedback;
    this.commit("setShared", copy);
  },

  async removeFromShared({ state, commit }, data: RemoveFromShared) {
    let msg = "";
    try {
      const endpoint = "/api/Sessions/RemoveFromShared";
      const allRemoved = (await api.put(endpoint, data)) as string[];
      const idx = state.sessions.findIndex(x => x.ID === data.id);
      if (idx === -1) return;
      const newSharedWith = state.sessions[idx].SharedWith.filter(x =>
        allRemoved.includes(x),
      );
      state.sessions[idx].SharedWith = newSharedWith;
      msg = i18n.t("sess.hanging.snack.removeAll").toString();
      if (data.sharedWith.length !== allRemoved.length)
        msg = i18n
          .t("sess.hanging.snack.removeSome", { emails: allRemoved.join(",") })
          .toString();
    } catch (error) {
      console.log(error);
      msg = i18n.t("sess.hanging.snack.removeFail").toString();
    }
    commit("displaySnackbar", msg, { root: true });
  },
};

export default actions;
