


























import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class RecordSession extends Vue {
  @Prop({ default: () => false }) mini!: boolean;
  @Getter("sessions/uploadingSession") loading!: boolean;
}
