// devextreme styles
import "devextreme/dist/css/dx.material.blue.light.css";

// Scaffold app
import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";

// Custom styles
import "./styles.scss";
import "./variables.scss";

// Middleware
import router from "@/core/router";
import store from "@/core/store";
import i18n from "@/core/plugins/i18n";
import vuetify from "@/core/plugins/vuetify";
import { SessionHub, NotificationHub } from "@/core/plugins/signalrconnection";

// USE LOCAL COPY OF materialdesignicons TO AVOID VIOLATING DSGVO
import "@mdi/font/css/materialdesignicons.min.css";

// Quill
// @ts-ignore
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
Vue.use(VueQuillEditor);

// videojs
import "video.js/dist/video-js.min.css";
import "videojs-record/dist/css/videojs.record.css";

// QuestionHub
Vue.use(SessionHub);
Vue.use(NotificationHub);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// @ts-ignore
import VueMasonry from "vue-masonry-css";
Vue.use(VueMasonry);

// @ts-ignore
import VueProgressiveImage from "vue-progressive-image";
Vue.use(VueProgressiveImage);

import VueFreshchat from "vue-freshchat";
Vue.use(VueFreshchat as any, {
  host: "https://wchat.eu.freshchat.com",
  appToken: "42065161-57a6-412a-8551-0327fb052824",
  config: {
    headerProperty: {
      hideChatButton: true,
    },
  },
});

import { DateTime } from "luxon";
Vue.filter("format", (v: any) => {
  let date;
  date = DateTime.fromJSDate(v);
  if (!date.isValid) date = DateTime.fromISO(v);
  return date.toLocaleString((DateTime.DATE_MED as any) as any);
});

Vue.filter("formatwithtime", (v: any) => {
  let date: DateTime | null = null;
  if (typeof v === "string") date = DateTime.fromISO(v);
  else date = DateTime.fromJSDate(v);

  // const addedHours = Number(date.toString().split("+")[1].split(":")[0]);
  // date = date.plus(Duration.fromMillis(addedHours * 60 * 60 * 1000));

  return date.toFormat("dd MMM yyyy, HH:mm");
});

Vue.filter("truncate", (v: string | undefined, maxChar: number = 300) => {
  if (!v) return "";
  if (v.length > maxChar) return v.substr(0, maxChar) + "...";
  return v;
});

console.log(
  "%cPREZP DEV CONSOLE",
  "font-weight: bold; font-size: 20px; color: rgba(255,255,255,0.8)",
);
console.log("%cv1.2.5", "font-size: 40px");

import { Component } from "vue-property-decorator";
Component.registerHooks(["beforeRouteLeave"]);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount("#app");
