
































import Vue from "vue";
import { Getter } from "vuex-class";
import UploadSession from "./UploadSession.vue";
import { Component, Prop } from "vue-property-decorator";
import { Permission } from "@/core/models";

@Component({ components: { UploadSession } })
export default class UploadAudio extends Vue {
  @Prop({ default: () => false }) mini!: boolean;
  @Getter("sessions/uploadingSession") loading!: boolean;
  @Getter("profile/permissions") permissions!: Permission[];

  dialog = false;

  get locked() {
    return !this.permissions.some(x => x.type === "upload_video");
  }
}
