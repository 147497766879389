import { GetterTree } from "vuex";

import { ProfileState } from ".";
import { RootState } from "../root";
import { Roles } from "@/core/models";
import { defaultPfp } from "@/core/utils/constants";

const getters: GetterTree<ProfileState, RootState> = {
  id: state => state.user?.ID,
  pfp: state => state.user?.Pfp || defaultPfp,
  isDefaultPfp: state => !state.user?.Pfp,
  showTutorial: state => state.user?.ShowTutorial,
  tutorialStep: state => state.user?.TutorialStep,
  isAuthenticated: state => state.isAuthenticated,
  getUserEmail: state => (state.user && state.user.Email.trim()) || "",
  getUserName: state => (state.user && state.user.Username.trim()) || "",
  getUserRole: state => (state.user && state.user.Role) || Roles.User,
  getUserHasPrivileges: state =>
    (state.user && state.user.Role != Roles.User) || false,
  userEmailVerified: state => (state.user && state.user.EmailVerified) || false,

  // Non-User specific
  getAllUsers: state => {
    const owners = state.allUsers.filter(x => x.Role == Roles.Owner);
    const admins = state.allUsers.filter(x => x.Role == Roles.Admin);
    const users = state.allUsers.filter(x => x.Role == Roles.User);
    return [...owners, ...admins, ...users];
  },

  getAllUsersLoading: state => state.allUsersLoading,
  changeUserRoleLoading: state => state.changeUserRoleLoading,
  changedUserRoleEmail: state => state.changedUserRoleEmail,
  forgotPasswordLoading: state => state.forgotPasswordLoading,
  forgotEmailSent: state => state.forgotEmailSent,
  resetPasswordLoading: state => state.resetPasswordLoading,
  confirmEmailLoading: state => state.confirmEmailLoading,
  confirmEmailSent: state => state.confirmEmailSent,

  createCompanyLoading: state => state.createCompanyLoading,
  createCompanySuccess: state => state.createCompanySuccess,
  editCompanyLoading: state => state.editCompanyLoading,
  upgradeLoading: state => state.upgradeLoading,
  loading: state => state.loading,

  isCompany: state => state.user && !!state.user.CustomerId,
  permissions: state => (state.user && state.user.Permissions) || [],

  baseline: state => state.baseline,
};

export default getters;
