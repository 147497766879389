







































































































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, PropSync } from "vue-property-decorator";
import { Permission } from "@/core/models";

@Component
export default class Menu extends Vue {
  @PropSync("expanded", { type: Boolean }) expandedProp!: boolean;
  @Getter("seminars/ownsSeminar") ownsSeminar!: boolean;
  @Getter("profile/getUserHasPrivileges") hasPrivileges!: boolean;
  @Getter("profile/permissions") permissions!: Permission[];

  selectedLink = "";
  get hasTrainingPermission() {
    return this.permissions?.some(x => x.type === "session_training");
  }

  get hasSeminarPermission() {
    return this.permissions?.some(x => x.type === "seminar");
  }

  get isStudent() {
    return this.permissions?.some(x => x.type === "student");
  }

  get isTeacher() {
    return this.permissions?.some(x => x.type === "teacher");
  }
}
