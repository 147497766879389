import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import store from "../store";
import {
  authGuard,
  adminGuard,
  trainingGuard,
  seminarGuard,
  // ownerGuard,
} from "./utils";

const maintenanceMode = process.env.VUE_APP_MAINTENANCE_MODE_ENABLED || false;

Vue.use(VueRouter);
const routes: RouteConfig[] = [
  ...(maintenanceMode
    ? [
        {
          name: "Maintenance",
          path: "**/*",
          component: () => import("@/views/maintenance"),
        },
      ]
    : []),
  {
    name: "Home",
    path: "/",
    component: () => import("@/views/home"),
  },

  // Legal (Always Public)
  {
    name: "Imprint",
    path: "/legal/imprint",
    component: () => import("@/views/legal/Imprint.vue"),
  },
  {
    name: "Privacy Policy",
    path: "/legal/privacy",
    component: () => import("@/views/legal/PrivacyPolicy.vue"),
  },

  // Legal fallthrough (Redirect to imprint)
  {
    name: "",
    path: "/legal**",
    component: () => import("@/views/legal"),
  },

  // Auth
  {
    name: "",
    path: "/auth",
    component: () => import("@/views/auth"),
    children: [
      {
        name: "Login",
        path: "login",
        component: () => import("@/views/auth/pages/Login.vue"),
      },
      {
        name: "Register",
        path: "register",
        component: () => import("@/views/auth/pages/Register.vue"),
      },
      {
        name: "Forgot Password",
        path: "forgotPassword",
        component: () => import("@/views/auth/pages/ForgotPassword.vue"),
      },
      {
        name: "Reset Password",
        path: "password/reset",
        component: () => import("@/views/auth/pages/ResetPassword.vue"),
      },
      {
        name: "Confirm Email",
        path: "email/confirm",
        component: () => import("@/views/auth/pages/ConfirmEmail.vue"),
      },
      {
        name: "Inactive Profile",
        path: "inactive",
        component: () => import("@/views/auth/pages/InactiveProfile.vue"),
      },
      {
        name: "New User",
        path: "new-user",
        component: () => import("@/views/auth/pages/NewUser.vue"),
      },
      {
        name: "Onboarding",
        path: "onboarding",
        component: () => import("@/views/auth/pages/Onboarding.vue"),
      },
      // Legal (In/Before Auth)
      {
        name: "Imprint",
        path: "imprint",
        component: () => import("@/views/legal/Imprint.vue"),
      },
      {
        name: "Privacy Policy",
        path: "privacy",
        component: () => import("@/views/legal/PrivacyPolicy.vue"),
      },
    ],
  },

  // Auth fallthrough (Redirect to login)
  {
    name: "",
    path: "/auth**",
    redirect: "/auth/login",
  },

  // Legal (In App)
  {
    name: "Imprint",
    path: "/imprint",
    component: () => import("@/views/legal/Imprint.vue"),
  },
  {
    name: "Privacy Policy",
    path: "/privacy",
    component: () => import("@/views/legal/PrivacyPolicy.vue"),
  },

  // Dash
  {
    name: "Dashboard",
    path: "/dashboard",
    component: () => import("@/views/dashboard"),
    beforeEnter: trainingGuard,
  },

  // Admin
  {
    name: "Admin",
    path: "/admin",
    component: () => import("@/views/admin"),
    beforeEnter: adminGuard,
    children: [
      {
        name: "Website Users",
        path: "users",
        component: () => import("@/views/admin/pages/Users.vue"),
      },
      {
        name: "How-To Videos",
        path: "videos",
        component: () => import("@/views/admin/pages/HowToVideos.vue"),
      },
      {
        name: "Slides",
        path: "slides",
        component: () => import("@/views/admin/pages/Slides.vue"),
      },
      {
        name: "Transcription",
        path: "transcription",
        component: () => import("@/views/admin/pages/Transcription.vue"),
      },
    ],
  },

  // Sessions
  {
    name: "Sessions (List)",
    path: "/sessions/list",
    component: () => import("@/views/sessions/Sessions.vue"),
    beforeEnter: trainingGuard,
  },
  {
    name: "Submitted Sessions",
    path: "/sessions/submitted",
    component: () => import("@/views/sessions/SubmittedSessions.vue"),
    beforeEnter: trainingGuard,
  },
  {
    name: "Sessions (Progress)",
    path: "/sessions/progress",
    component: () => import("@/views/sessions/Sessions.vue"),
    beforeEnter: trainingGuard,
  },
  {
    name: "Shared Sessions",
    path: "/sessions/shared/:email?",
    component: () => import("@/views/sessions/ManagedSessions.vue"),
    beforeEnter: trainingGuard,
  },

  // Groups
  {
    name: "Groups",
    path: "/groups",
    component: () => import("@/views/groups/Groups.vue"),
    beforeEnter: trainingGuard,
  },
  {
    name: "Group Details",
    path: "/groups/:id",
    component: () => import("@/views/groups/GroupDetails.vue"),
    beforeEnter: trainingGuard,
  },

  // News
  // {
  //   name: "News",
  //   path: "/news",
  //   component: () => import("@/views/news/News.vue"),
  //   beforeEnter: trainingGuard,
  // },
  // {
  //   name: "Article",
  //   path: "/news/article/:id",
  //   component: () => import("@/views/news/ArticleDetails.vue"),
  //   beforeEnter: trainingGuard,
  // },

  // Goals
  {
    name: "Goals",
    path: "/goals",
    component: () => import("@/views/goals"),
    beforeEnter: trainingGuard,
  },

  // Meetings
  {
    name: "Meetings",
    path: "/meetings",
    component: () => import("@/views/meetings"),
    beforeEnter: authGuard,
  },

  // Seminars
  {
    path: "/seminars",
    component: () => import("@/views/seminars"),
    children: [
      {
        path: "",
        name: "Seminars - Home",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Home.vue"),
      },
      {
        path: "dashboard/:id?",
        name: "Seminars - Dashboard",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Dashboard.vue"),
      },
      {
        path: "details/:id?",
        name: "Seminars - Details",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Details.vue"),
      },
      {
        path: "videos/:id?",
        name: "Seminars - Video",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Videos.vue"),
      },
      {
        path: "edit/:id",
        name: "Seminars - Edit",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Edit.vue"),
      },
      {
        path: "admin",
        name: "Seminars - Admin Dashboard",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Admin.vue"),
      },
      {
        path: "submission/:id",
        name: "Seminars - Submission Details",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/SubmissionDetails.vue"),
      },
    ],
  },

  // Recorder
  {
    name: "Recorder",
    path: "/recorder",
    component: () => import("@/views/recorder"),
    beforeEnter: authGuard,
  },

  // Settings
  {
    name: "Settings",
    path: "/settings",
    component: () => import("@/views/settings"),
    beforeEnter: authGuard,
  },

  // Fallthrough
  {
    path: "**",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((_, _1, next) => {
  global.window.scrollTo(0, 0);
  // Scroll overflow divs for legal pages
  if (document.querySelector(".right-panel:has(> .legal-page)"))
    (document.querySelector(
      ".right-panel:has(> .legal-page)",
    ) as any).scrollTop = 0;
  next();
});

router.beforeEach((_, _1, next) => {
  store.commit("appLoading");
  next();
});

router.afterEach(() => store.commit("appLoaded"));

export default router;
