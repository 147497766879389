import store from "@/core/store";
import Storage from "@/core/utils/LocalStorage";
import { Permission, Roles } from "@/core/models";
import { NavigationGuardNext, Route } from "vue-router";

type Next = NavigationGuardNext<Vue>;

// helpers
const isAuth = () => {
  const token = Storage.getAccessToken();
  return !!token;
};

const hasSeminarPermission = () => {
  const permissions = store.getters["profile/permissions"] as
    | Permission[]
    | undefined;
  return permissions?.some(x => x.type === "seminar");
};

const hasTrainingPermission = () => {
  const permissions = store.getters["profile/permissions"] as
    | Permission[]
    | undefined;
  return permissions?.some(x => x.type === "session_training");
};

const isAdmin = () =>
  Storage.getUserRole() === Roles.Owner ||
  Storage.getUserRole() === Roles.Admin;

// Guards
const authGuard = (_to: Route, _from: Route, next: Next) => {
  if (!isAuth()) next("/");
  else next();
};

const adminGuard = (_to: Route, _from: Route, next: Next) => {
  if (!isAuth()) next("/");
  else if (!isAdmin()) next("/dashboard");
  else next();
};

const trainingGuard = (_to: Route, _from: Route, next: Next) => {
  if (!isAuth() || !hasTrainingPermission()) next("/");
  else next();
};

const seminarGuard = (_to: Route, _from: Route, next: Next) => {
  if (!isAuth()) next("/");
  else if (!hasSeminarPermission()) next("/dashboard");
  else next();
};

export { authGuard, adminGuard, trainingGuard, seminarGuard };
