















































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";

import CurrentUser from "./components/CurrentUser.vue";
import ToolbarMenu from "./components/ToolbarMenu.vue";
import LanguageSelect from "./components/LanguageSelect.vue";
import ProfileDropdown from "./components/ProfileDropdown.vue";
import Notifications from "./components/Notifications.vue";
import SeminarSelect from "./components/SeminarSelect.vue";
import BrowserWarning from "./components/BrowserWarning.vue";

@Component({
  components: {
    CurrentUser,
    ToolbarMenu,
    LanguageSelect,
    ProfileDropdown,
    Notifications,
    SeminarSelect,
    BrowserWarning,
  },
})
export default class AppBar extends Vue {
  @Getter("theme") theme!: any;
  @Getter("appLoading") appLoading!: boolean;
  @Getter("profile/loading") profileLoading!: boolean;
  @Getter("profile/forgotEmailSent") forgotEmailSent!: boolean;
  @Getter("profile/confirmEmailSent") confirmEmailSent!: boolean;
  @Getter("profile/userEmailVerified") userEmailVerified!: boolean;
  @Action("profile/sendConfirmEmail") sendConfirmEmail!: Function;

  @Prop({ default: () => false }) isMobile!: boolean;
  showBanner: boolean = false;

  get isChrome() {
    return navigator.userAgent.indexOf("Chrome") !== -1;
  }

  sending: boolean = false;
  sendEmail() {
    if (this.sending) return;
    this.sending = true;
    this.sendConfirmEmail();
  }

  @Watch("$route", { immediate: true })
  urlChanged() {
    this.showBanner = !this.userEmailVerified;
    if (this.$route.path.startsWith("/auth")) this.showBanner = false;
  }

  @Watch("profileLoading", { immediate: true })
  userLoaded() {
    this.showBanner = !this.userEmailVerified;
    if (this.$route.path.startsWith("/auth")) this.showBanner = false;
  }
}
