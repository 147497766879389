import { ActionTree } from "vuex";

import router from "@/core/router";
import { User } from "@/core/models";
import i18n from "@/core/plugins/i18n";
import api from "@/core/utils/api";
import LocalStorage from "@/core/utils/LocalStorage";
import UserData from "@/core/models/UserData";
import BaselineAnalysis from "@/core/models/sessions/BaselineAnalysis";

import { ProfileState } from ".";
import { RootState } from "../root";

const actions: ActionTree<ProfileState, RootState> = {
  async logoutUser({ state, dispatch }) {
    state.user = undefined;
    state.isAuthenticated = false;
    LocalStorage.clearStorage();
    dispatch("clearEverything", null, { root: true });
  },
  clear: ({ state }) => (state.baseline = new BaselineAnalysis(null)),

  // login/register/load
  async loginUser({ state, rootState, commit, dispatch }, { email, password }) {
    state.loading = true;
    try {
      const res = (await api.post("/api/Account/Login", {
        email,
        password,
        from: "web",
      })) as any;
      const { userData: data, accessToken, refreshToken } = res as any;

      const userData = new UserData(data);
      LocalStorage.setAccessToken(accessToken);
      LocalStorage.setRefreshToken(refreshToken);

      state.isAuthenticated = true;
      dispatch("setEverything", userData, { root: true });
      router.replace("/");
      rootState.dataLoadSuccess = true;
      rootState.active = userData.active;

      window.dispatchEvent(new Event("user-logged-in"));
    } catch (error) {
      const msg = i18n.t(`api_errors.${(error as any).description}`).toString();
      dispatch("displaySnackbar", msg, { root: true });
      commit("userLoadError");
    }

    state.loading = false;
    commit("loaded", null, { root: true });
  },
  async registerUser({ state, rootState, commit, dispatch }, data) {
    state.loading = true;
    try {
      const res = (await api.post("/api/Account/Register", data)) as any;
      const { user, emailToken } = res;
      const userData = new UserData({ user });
      dispatch("sendConfirmEmail", { email: user.email, token: emailToken });
      dispatch("setEverything", userData, { root: true });
      rootState.dataLoadSuccess = true;
      rootState.active = userData.active;
      router.replace("/auth/new-user");
    } catch (error) {
      if ((error as any).description === "user_exists") {
        const msg = i18n.t("api_errors.user_exists").toString();
        dispatch("displaySnackbar", msg, { root: true });
      } else commit("userLoadError");
    }
    state.loading = false;
    commit("loaded", null, { root: true });
  },

  async loadUser({ state, commit, dispatch }) {
    state.loading = true;
    try {
      const res = (await api.get("/api/Users/GetByToken")) as any;
      const { email, username, role, emailVerified, permissions } = res;
      console.log(res);
      LocalStorage.setUserRole(role);
      state.user = new User(email, username, role, emailVerified, permissions);
    } catch (error) {
      dispatch(
        "displaySnackbar",
        (error as any).description || "Could not load user data",
        { root: true },
      );
      commit("userLoadError");
    }
    state.loading = false;
  },

  // sending emails
  async resetPassword({ state, dispatch }, data) {
    state.resetPasswordLoading = true;
    try {
      await api.post("/api/Account/MakePasswordResetResetPassword", data);
      router.push("/auth/login");
    } catch (error) {
      dispatch(
        "displaySnackbar",
        (error as any).description || "Reset password fail",
        { root: true },
      );
      console.log(error);
    }
    state.resetPasswordLoading = false;
  },
  async sendConfirmEmail({ state, dispatch }, payload) {
    const emailTimeout = 10 * 1000;
    state.confirmEmailLoading = true;
    try {
      await api.post("/api/Account/SendEmailVerification", payload);
      state.confirmEmailSent = true;
      setTimeout(() => (state.confirmEmailSent = false), emailTimeout);
    } catch (error) {
      dispatch(
        "displaySnackbar",
        (error as any).description || "Reset password fail",
        { root: true },
      );
      state.confirmEmailSent = false;
    }
    state.confirmEmailLoading = false;
  },
  async confirmEmail({ state, dispatch }, data) {
    state.confirmEmailLoading = true;
    try {
      await api.post("/api/Account/ConfirmEmail", data);
    } catch (error) {
      dispatch(
        "displaySnackbar",
        (error as any).description || "Error when confirming email",
        { root: true },
      );
    }
    state.confirmEmailLoading = false;
  },

  // pfp
  async uploadPfp({ state }, file: File) {
    try {
      const end = "/api/Users/UploadProfilePicture";
      const data = new FormData();
      data.append("image", file);
      const uri = (await api.post(end, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })) as string;
      if (!state.user) return;
      state.user.Pfp = uri;
    } catch (error) {
      console.log(error);
    }
  },
  async removePfp({ state }) {
    try {
      const end = "/api/Users/RemoveProfilePicture";
      await api.delete(end);
      if (!state.user) return;
      state.user.Pfp = "";
    } catch (error) {
      console.log(error);
    }
  },

  // tutorial
  async advanceTutorial({ state }) {
    try {
      const end = "/api/Users/Advance";
      await api.patch(end);
      if (!state.user) return;
      state.user.TutorialStep = state.user.TutorialStep + 1;
    } catch (error) {
      console.log(error);
    }
  },
  async completeTutorial({ state }) {
    try {
      const end = "/api/Users/Complete";
      await api.patch(end);
      if (!state.user) return;
      state.user.TutorialStep = 0;
      state.user.ShowTutorial = false;
    } catch (error) {
      console.log(error);
    }
  },

  // add users from excel
  async addUsersFromExcel({ state }, file: File) {
    try {
      console.log("profile/actions=>addUsersFromExcel: ", file);
      const data = new FormData();
      data.append("excelFile", file);
      await api.post("/api/Users/AddFromExcel", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!state.user) return;
    } catch (error) {
      console.log(error);
    }
  },
};

export default actions;
